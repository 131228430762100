import React, { FunctionComponent } from "react"
import SEO from "../components/seo/seo"
import RichTextRenderer from "../components/rich-text-handler/rich-text-handler"
import ArticleCard from "../components/ArticleCard"
import "../assets/styles/page/docprojectpage.scss"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Facebookshare from "../components/facebookshare"
import Tweetbutton from "../components/tweetbutton"

const Projectpage: FunctionComponent = pageContext => {
  const {
    projectDescription,
    metaTitle,
    titleOfProject,
    projectBody,
    bannerUrl,
    aboutProjectAuthor,
    projectAuthorImage,
    contentful_id
  } = pageContext?.pageContext.edge

  const ProjectArray = pageContext.pageContext.data

  return (
    <>
      <SEO title={titleOfProject} description={projectDescription} image={bannerUrl.fluid.src} />
      <Layout>
        <div className="project--content--container">
          <div className="project--content--inner">
            <article className="main--project--content">
              <div className="project--banner--wrapper">
                <div
                  className="project--banner--image--wrapper"
                  style={{
                    backgroundImage: `url(${bannerUrl.fluid.src})`
                  }}
                ></div>
                <div className="banner--overlap--wrapper">
                  <div className="banner--overlap--text--inner">
                    <h2 className="banner--overlap--headtext">{titleOfProject}</h2>
                    <p className="banner--overlap--subtext">{projectDescription}</p>
                    <p className="meta">{metaTitle}</p>
                  </div>
                </div>
              </div>
              <div className="project--main--content">
                <RichTextRenderer content={projectBody.json} />
              </div>
              <div className="share--article--media">
                <Facebookshare />
                <Tweetbutton titleOfArticle={titleOfProject} />
              </div>
              <div className="project--author--details--wrapper">
                <div
                  className="author--image"
                  style={{
                    backgroundImage: `url(${projectAuthorImage.fluid.src})`
                  }}
                ></div>
                <div className="about--author">
                  <p>{aboutProjectAuthor}</p>
                </div>
              </div>
            </article>
          </div>
          <div className="upnext--project--wrapper">
            <div id="next-text">
              <h2>Up Next</h2>
            </div>
            <div className="project--list">
              {ProjectArray.slice(0, 4)
                .filter((e: { contentful_id: any }) => e.contentful_id !== contentful_id)
                .map(
                  (articles: {
                    bannerUrl: any
                    titleOfProject: any
                    contentful_id: any
                    projectDescription: any
                    metaTitle: any
                    slug: any
                  }) => {
                    const { bannerUrl, titleOfProject, contentful_id, projectDescription, metaTitle, slug } = articles

                    return (
                      <Link
                        to={`/doc/project/doc-projects/${slug}`}
                        className="artile--card--wrapper--2"
                        key={contentful_id}
                      >
                        <ArticleCard
                          key={contentful_id}
                          articleImage={bannerUrl.fluid.src}
                          articleHeadtext={titleOfProject}
                          articleSubtext={projectDescription}
                          meta={metaTitle}
                        />
                      </Link>
                    )
                  }
                )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Projectpage
